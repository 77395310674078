<template>
    <div id="writeblog">
        <div class="top">
            <input type="text" class="title" placeholder="请输入标题..." v-model="title">
            <div class="checkbox">
                <el-row justify="center" type="flex" align="middle">
                    <el-radio-group v-model="categoryValue">
                        <el-radio v-for="item in category" :label="item" :border=true size="mini" class="radio">
                            {{item}}</el-radio>
                    </el-radio-group>
                </el-row>
            </div>
        </div>
        <div class="mid">
            <textarea class="digest" placeholder="请输入文章的摘要..." v-model="digest"></textarea>
        </div>
        <div class="simplemde-container">
            <textarea name="blog" id="text" class="blogArea"></textarea>
        </div>
    </div>
</template>

<script>
    import 'font-awesome/css/font-awesome.min.css'
    import SimpleMDE from 'simplemde'
    import 'simplemde/dist/simplemde.min.css';
    import hljs from "highlight.js";

    const marked = require("marked");
    import "highlight.js/styles/github.css";
    import "github-markdown-css";

    import createBlog from "network/createBlog";
    import modifyBlog from "network/modifyBlog";
    import {
        Base64
    } from 'js-base64';

    export default {
        name: "writeblog",
        data() {
            return {
                simplemde: {},
                category: ['前端', 'iOS', 'JavaScript', 'Java', 'Flutter'],
                categoryValue: "iOS",
                title: this.blogItem.title,
                digest: this.blogItem.digest,
                objectId: ''
            };
        },
        props: {
            blogItem: {
                type: Object,
                default () {
                    return {
                        categoryValue: "iOS",
                        title: "",
                        digest: ""
                    }

                }
            }

        },
        mounted() {
            var rendererMD = new marked.Renderer(); // 初始化
            marked.setOptions({
                //设置样式
                renderer: rendererMD,
                highlight: function (code) {
                    return hljs.highlightAuto(code).value;
                },
                pedantic: false,
                gfm: true,
                tables: true,
                breaks: true,
                sanitize: false,
                smartLists: true,
                smartypants: false,
                xhtml: false
            });

            var simplemde = new SimpleMDE({
                // 绑定元素
                element: document.getElementById("text"),
                // 禁用拼写检查
                spellChecker: false,
                // 自动聚焦
                autofocus: true,
                // 不用自动下载字体
                autoDownloadFontAwesome: false,
                placeholder: "Type here...",
                autosave: {
                    enabled: true,
                    uniqueId: "mamba-blog",
                    delay: 1000,
                },
                tabSize: 4,
                status: false,
                lineWrapping: false,
                renderingConfig: {
                    codeSyntaxHighlighting: true
                },
                previewRender: function (plainText,
                    preview) { // 异步方法，这里为了使用marked的解析，必须给preview加上一个markdown-body的类名
                    setTimeout(function () {
                        preview.innerHTML =
                            `<div class="markdown-body">${marked.parse(plainText)}</div>`
                    }, 250);
                },
                // minHeight: "700px"
            });
            this.simplemde = simplemde;


            // 拿到markdown的文本
            simplemde.codemirror.on("change", function () {
                // console.log(simplemde.value());
            });

            simplemde.gui.toolbar.remove()
            simplemde.toolbar.push({
                name: 'metadata',
                action: () => {
                    // 判断信息是否填完整了
                    if (!this.judgeContent()) {
                        this.$alert.show('请将博客内容填写完整!');
                        return;
                    }
                    if (this.objectId) {
                        this.modifyBlog();

                    } else {
                        this.createBlog();
                    }
                },
                className: 'fa fa-check',
                title: '发布文章'
            }, {
                name: 'clear',
                action: () => {
                    // 直接看官网文档。里面有很多api接口的文档
                    // this.simplemde.codemirror.execCommand('selectAll');
                    this.simplemde.codemirror.setValue('');
                },
                className: 'fa fa-window-close',
                title: '清除编辑器内容'

            })
            simplemde.createToolbar()
            // 试了很多种自定义高度的方法，好像只有这里设置才有效果
            document.querySelector(".CodeMirror").style.height = "700px"
            // editor-preview-side是可以拿到的，加上了markdown-body的类后可以生效
            // var editorLeft = document.getElementsByClassName('editor-preview-side')[0];
            // editorLeft.classList.add('markdown-body');

            // editor-preview这个类只有在点击预览的时候才会生成，所以拿不到
            // var editorRight = document.getElementsByClassName('editor-preview')[0];
            // editorRight.classList.add('markdown-body')

            // 这里不要用fuction函数，不然this的指向不对
            window.addEventListener("popstate", (event) => {
                this.$alert.hidden();
            })

            this.initUI();

        },
        methods: {
            createBlog() {
                let date = new Date();
                let dateStr = date.toLocaleString('chinese', {
                    hour12: false
                });
                var blog = {
                    title: this.title,
                    category: this.categoryValue,
                    content: Base64.encode(this.simplemde.value()),
                    digest: this.digest,
                    date: dateStr
                }
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                createBlog(blog).then(res => {
                    loading.close();
                    if (res.objectId) {
                        this.$alert.show('博客发布成功!', 'good')
                    }
                }).catch(err => {
                    loading.close();
                    this.$alert.show('博客发布失败!:' + err, 'warning')
                });
            },
            modifyBlog() {
                let date = new Date();
                let dateStr = date.toLocaleString('chinese', {
                    hour12: false
                });
                var blogItem = {
                    title: this.title,
                    category: this.categoryValue,
                    content: Base64.encode(this.simplemde.value()),
                    digest: this.digest,
                    date: dateStr
                }
                var payload = {
                    objectId: this.objectId,
                    blogItem: blogItem
                }
                const loading = this.$loading({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                })
                modifyBlog(payload).then(res => {
                    loading.close();
                    if (res.updatedAt) {
                        this.$alert.show('博客更新成功!', 'good')
                    }
                }).catch(err => {
                    loading.close();
                    this.$alert.show('博客更新失败!:' + err, 'warning')
                });

            },
            judgeContent() {
                if (!this.title || !this.digest || !this.simplemde.value()) {
                    return false;
                } else {
                    return true;
                }
            },
            initUI() {
                if (this.$route.params.type == 'modifyBlog') {
                    this.title = this.$store.state.blogItem.title;
                    this.digest = this.$store.state.blogItem.digest;
                    this.categoryValue = this.$store.state.blogItem.category;
                    this.simplemde.codemirror.setValue(Base64.decode(this.$store.state.blogItem.content));
                    this.objectId = this.$route.params.objectId;
                }

            }
        },
        destroyed() {

        }
    };
</script>
<style lang='less' scoped>
    #personal {
        height: 100%;
    }

    // 自定义高度在这里加了没效果，去掉了scoped才有效果，但是对首页的布局会有影响
    .simplemde-container>.CodeMirror {
        width: 100%;
        line-height: 20px;
        padding-left: 25px;
    }

    .simplemde-container>.CodeMirror-code {
        padding-bottom: 40px;
    }

    .simplemde-container>.editor-statusbar {
        display: none;
    }

    .simplemde-container>.CodeMirror .CodeMirror-code .cm-link {
        color: #1890ff;
    }

    .simplemde-container>.CodeMirror .CodeMirror-code .cm-string.cm-url {
        color: #2d3b4d;
    }

    .simplemde-container>.CodeMirror .CodeMirror-code .cm-formatting-link-string.cm-url {
        padding: 0 2px;
        color: #E61E1E;
    }

    .simplemde-container>.editor-toolbar.fullscreen,
    .simplemde-container>.CodeMirror-fullscreen {
        z-index: 1003;
    }

    .simplemde-container>.fa-columns,
    .simplemde-container>.fa-arrows-alt,
    .simplemde-container>.fa-eye,
    .simplemde-container>.fa-question-circle,
    .simplemde-container>.separator,
    .simplemde-container>.fa-quote-left {
        display: none;
    }

    .top {
        display: flex;
    }

    .title {
        padding: 0 25px;
        flex: 4;
    }

    .checkbox {
        display: flex;
        justify-content: flex-start;
        flex: 2;
        background-color: #FFFFFF;
        padding: 0 25px;
    }

    .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    input[type='text'] {
        /* px-to-viewport-ignore-next */
        font-size: 24PX;
        font-weight: 500;
        color: #1d2129;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB,
            Microsoft YaHei, Arial, sans-serif;

    }

    .mid {
        border-top: 1px solid #CDCDCD;
        height: 200px;
        display: flex;
    }

    .digest {
        height: 100%;
        flex: 1;
        padding-top: 15px;
        padding-left: 25px;
        padding-right: 25px;
        /* px-to-viewport-ignore-next */
        font-size: 15PX;
        font-weight: 500;
        color: #1d2129;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB,
            Microsoft YaHei, Arial, sans-serif;
        resize: none;
        box-sizing: border-box;

    }

    .el-radio.is-bordered+.el-radio.is-bordered {
        margin-left: 0px;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    @media (max-width: 750px) {
        .title {
            flex: 2;
            border-bottom: 1px solid #CDCDCD;
        }

        .checkbox {
            padding-bottom: 2px;
            padding-top: 2px;
            flex: 1;
        }

        .mid {
            height: 100px;
        }

        .top {
            display: flex;
            flex-direction: column;
            height: 150px;
        }

        input[type='text'] {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

        }

    }
</style>