import { request } from "./request";

export default function modifyBlog(payload) {
    return request({
        url: '/classes/blogItem/' + payload.objectId,
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            'X-LC-Id': 'r57JSq9yQU4WHkBh3WlJktM0-gzGzoHsz',
            'X-LC-Key': '6B1HmkLavgUf3SKXAGziVUhK'
        },
        data: JSON.stringify(payload.blogItem)
    })
}